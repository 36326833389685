import { EnvBp } from '@bcf-v2-api-access/config-tokens/env-bp';
import { EnvCms } from '@bcf-v2-api-access/config-tokens/env-cms';
import { EnvCp } from '@bcf-v2-api-access/config-tokens/env-cp';
import { EnvEeCms } from '@bcf-v2-api-access/config-tokens/env-ee-cms';
import { EnvBase } from '@bcf-v2-configs/env-base';
import { EnvFe } from '@bcf-vanilla-ts-v1-api-access/config-tokens/env-fe';
import { device } from '@device/device';
import { environment } from '@env/env';
import { environment as environmentSports } from '@env/env-sports';

export const envBase: EnvBase = {
  brandName: environment.brandName,
  availableLangs: environment.langs,
  translationsBrandName: environment.brandI18Name,
  translationsAllowedBucketLangs: environment.langs,
  translationsLangsMap: environment.langsI18nMap,
  cookiesMap: environment.cookiesFromUrlMap
};

export const envCms: EnvCms = {
  cmsApiUrl: environment.cmsHeadlessApiUrl!
};

export const envEeCms: EnvEeCms = {
  cmsApiUrl: environment.cmsApiUrl!,
  cmsBrandNamePrefix: environment.cmsBrandNamePrefix,
  cmsLangPrefix: environment.cmsLangPrefix,
  cmsDevicePrefix: environment.cmsDevicePrefix,
  brandCmsName: environment.brandCmsName,
  cmsHeadlessEnabled: environment.cmsHeadlessEnabled,
  deviceType: device.type,
  overrideForCms: device.overrideForCms
};

export const envBp: EnvBp = {
  bpApiUrl: environmentSports.bpApiUrl,
  bpApiUrlV2: environmentSports.bpApiUrlV2,
  bpApiUrlV2MyBets: environmentSports.bpApiUrlV2MyBets,
  bpApiUrlV2System: environmentSports.bpApiUrlV2System,
  offerApiHttpUrl: environmentSports.matchOfferApiUrl,
  offerApiWsUrl: environmentSports.matchOfferSocketUrl,
  offerMatchStatsApiWsUrl: environmentSports.matchOfferSocketMatchStatsUrl
};

export const envCp: EnvCp = {
  userApiUrl: environment.userApiUrl,
  walletApiUrl: environment.walletApiUrl,
  paymentApiUrl: environment.paymentApiUrl
};

export const envFe: EnvFe = {
  baseUrl: environment.feEnv!.baseUrl,
  savePinLoginEndpoint: environment.feEnv!.savePinLoginEndpoint,
  pinLoginEndpoint: environment.feEnv!.pinLoginEndpoint
};
