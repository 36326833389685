import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable, mergeMap, retryWhen, throwError, timer } from 'rxjs';

export function status0RetryInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  return next(req).pipe(
    retryWhen((errors: Observable<HttpErrorResponse | unknown>) =>
      errors.pipe(
        mergeMap((error: HttpErrorResponse | unknown, i: number) => {
          const retryAttempt: number = i + 1;
          if (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 504) && retryAttempt < 4) {
            return timer(1500 * i);
          }
          return throwError(error);
        })
      )
    )
  );
}
