import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Inject, makeEnvironmentProviders } from '@angular/core';
import { DEVICE_BASE_KIND } from '@bcf-v2-utilities/device-info/token';
import { registerWorkerInstance } from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/tokens';
import {
  initWorkerMessage,
  workerCookiesMessage,
  workerDeviceInfoMessage,
  workerUrlMessage
} from '@bcf-vanilla-ts-v1-platforms/platform-worker/common/messages';
import { DeviceBaseType } from '@bcf-vanilla-ts-v1-utilities/device-info/types';
import { extractDevice } from '@bcf-vanilla-ts-v1-utilities/device-info/utils';
import { BrowserSideBlocController } from './browser-side-bloc-controller';
import { WorkerWrapper } from './worker-wrapper';
import { WorkerWrapperVirtual } from './worker-wrapper-virtual';

export function provideWorker(worker: Worker, appRuntimeId: number): EnvironmentProviders {
  registerWorkerInstance(worker);
  worker.postMessage(initWorkerMessage(appRuntimeId));
  let previousCookies: string | undefined;
  let previousPathname: string | undefined;

  return makeEnvironmentProviders([
    {
      provide: WorkerWrapper,
      useFactory: () => new WorkerWrapper(worker)
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (document: Document, deviceKindBase: DeviceBaseType) => () => {
        setInterval(() => {
          const currentCookies: string = document.cookie;
          if (previousCookies !== currentCookies) {
            worker.postMessage(workerCookiesMessage(document.cookie));
            previousCookies = currentCookies;
          }

          const currentPathname: string = document.location.pathname;
          if (previousPathname !== currentPathname) {
            worker.postMessage(workerUrlMessage(currentPathname, document.location.href));
            previousPathname = document.location.pathname;
          }
        }, 10);
        worker.postMessage(workerDeviceInfoMessage(extractDevice(deviceKindBase, window, navigator)));
      },
      deps: [[new Inject(DOCUMENT)], [new Inject(DEVICE_BASE_KIND)]],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (browserSideBlocController: BrowserSideBlocController<any>) => () => {
        browserSideBlocController.init();
      },
      deps: [BrowserSideBlocController],
      multi: true
    }
  ]);
}

export function provideWorkerVirtual(appRuntimeId: number): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: WorkerWrapper,
      useClass: WorkerWrapperVirtual
    },

    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (workerWrapperVirtual: WorkerWrapperVirtual) => () => {
    //     replaceDiImpl(WorkerWrapper, () => workerWrapperVirtual);
    //   },
    //   deps: [WorkerWrapperVirtual],
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: (workerWrapper: WorkerWrapper) => () => {
        workerWrapper.postMessage(initWorkerMessage(appRuntimeId));
      },
      deps: [WorkerWrapper],
      multi: true
    }
  ]);
}
